import React from 'react'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="icons">
                <li><a href="https://www.facebook.com/pg/marcontanks/" target="_blank" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                {/* <li><a href="#" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li> */}
            </ul>
            <ul className="copyright">
                <li>&copy; 2020</li>
                <li>Made by: <a href="https://shaneo.ie">shaneo.ie</a></li>
            </ul>
        </div>
    </footer>
)

export default Footer
