import React from 'react'
import IrelandMap from "./../pages/irelandMap"

const Contact = (props) => (
    <section id="contact">
            <section>
            <div className="grid-wrapper inner">
                    <div className="contact-method col-4">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="mailto:marcontanks@gmail.com">Marcontanks@gmail.com</a>
                    </div>
                    <div className="contact-method col-4">
                        <span className="icon alt fa-phone"></span>
                        <h3>Phone</h3>
                        <a href="tel:+353879216130">(087) 9216130</a>
                    </div>
                    <div className="contact-method col-4">
                        <span className="icon alt fa-home"></span>
                        <h3>Address</h3>
                        <span>Marcon Tanks,<br />
                        Umerafree,<br />
                        Corduff,<br />
                        Carrickmacross,<br />
                        Co.Moanghan</span>
                    </div>
                    
            
            <div className=" col-12">
                        <IrelandMap />
                    </div>
                    </div>
            </section>
    </section>
)

export default Contact
